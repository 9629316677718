(function () {
  'use strict';

  class GroupsRest {
    constructor($resource, env) {
      this.$resource = $resource;
      this.env = env;
    }

    groups() {
      return this.$resource(`${this.env.api}/groups`);
    }

    myGroups() {
      return this.$resource(`${this.env.api}/groups/my-groups`);
    }

    group() {
      return this.$resource(`${this.env.api}/groups/:id/:metrics`, {id: '@id', metrics: '@metrics'}, {update: {method: 'PUT'}});
    }

    modules() {
      return this.$resource(`${this.env.api}/groups/:id/current-modules/:module_id`, {id: '@id', metrics: '@metrics', module_id: '@module_id'}, {update: {method: 'PUT'}});
    }

    moduleSet() {
      return this.$resource(`${this.env.api}/groups/:id/current-modules/:current_module_id`, {id: '@id', metrics: '@metrics', current_module_id: '@current_module_id'}, {update: {method: 'PUT'}});
    }

    lomMetadata() {
      return this.$resource(`${this.env.api}/groups/:id/lom-metadata`);
    }

    removeStudent() {
      return this.$resource(`${this.env.api}/groups/:id/:student_id`, {id: '@id', student_id: '@studentId'});
    }

  }

  /**
   * @ngdoc service
   * @name components.service:GroupsRest
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('GroupsRest', GroupsRest);
}());
